<template>
    <div class="main-content" style="
      padding-bottom: 10vh !important
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width: 100%;
        background-position: center;
      ">
        <div class="dashboard-banner position-relative">
            <div class="d-flex justify-content-center align-items-end">
            <!-- <h4 class="mt-5 mx-4 text-center text-primary">{{ $t("dashboard") }}</h4> -->
            </div>
            <div class="position-absolute h-100 w-100" style="background: #d2d2d24d" v-if="isLoading">
            <div class="px-2 py-1 rounded" style="
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: #add8e6;
                ">
                <p class="mb-0">{{ $t("loading...") }}</p>
            </div>
            </div>
        </div>
        <div class="m-3 mx-3 mt-4">
            <div class="header">
                <img src="../../../assets/images/etnex_buy/back_icon.png" alt="Back" class="back-arrow-icon" @click="goBack">
                <h1 class="main_text text text_main_title" style="margin-right: 18px;">{{ $t("record") }}</h1>
                <!-- <i class="fa fa-history info-icon" aria-hidden="true"></i> -->
                <!-- <img src="../../../assets/images/etnex_buy/question.png" alt="Info" class="info-icon"> -->
            </div>
            <div class="scrollable-container">
              <div v-if="packageRecord.length > 0">
                <div class="notification-card px-3 py-3" v-for="record in packageRecord" :key="record.id">
                    <div class="row align-items-center">
                        <div class="col-2">
                          <div class="icon">
                              <img src="../../../assets/images/etnex_earnings/robot.png" alt="icon" class="icon">
                          </div>
                        </div>
                        <div class="col-5">
                            <p class="mb-0 title text">{{ $i18n.locale === 'en' ? record.package.name : record.package.name_cn }} </p>
                            <span class="date text" style="letter-spacing: normal; color: rgb(127, 127, 127);">
                              
                                <div class="mr-2 text-white" style="display: inline-block;">
                                  <i class="fa fa-calendar" aria-hidden="true"></i> {{ formatDate(record.created_at) }}
                                </div>
                            </span>
                        </div>
                        <div class="text-right col-5">
                            <p class="text text_14 weight_600 text-light-green mb-0" style="line-height: 24px;">
                              {{ record.price }} ETNEX
                            </p>
                        </div>
                    </div>
                    <!-- <div class="content">
                      <div class="upper-row">
                        <span class="title text">{{ record.package.package_name }}</span>
                        <span class="date text">{{ formatDate(record.created_at) }}</span>
                      </div>
                      <div class="lower-row">
                          <span class="amount text"></span>
                          <span class="change">{{ record.action }}${{ record.found }}</span>
                      </div>
                    </div> -->
                </div>
              </div>
              <h3 v-else class="no-data-text text">
                {{ $t("no_data") }}
              </h3>
              <div class="purchase-statement-container" style="text-align: center; padding-top: 10px;" v-if="moreRecords">
                <button class="make-purchase-button" style="width: 40% !important;" @click="loadMore">
                    {{ $t("load_more") }}
                </button>
              </div>
            </div>
        </div>
    </div>
  </template>
  <script>
  import { getStakingRecord } from "../../../system/api/api";
  import { handleError } from "../../../system/handleRes";
  import { mapGetters } from "vuex";
  export default {
    components: {
      ...mapGetters(["lang"]),
    },
    data() {
      return {
        currentPage: 1,
        packageRecord: [],
        moreRecords: false,
      };
    },
    mounted() {
      this.loadItems();
    },
  
    methods: {
      
      goBack() {
        this.$router.go(-1)
      },
  
      formatDate(datetime) {
        return datetime.split(' ')[0];
      },
      loadMore() {
        this.currentPage++;
        this.loadItems();
      },
      loadItems(){
        var result = getStakingRecord(
          this.currentPage,
        );
        var self = this;
        result
          .then(function (value) {
            // console.log("API Response:", value);
            self.packageRecord = self.packageRecord.concat(value.data.data.user_package.data);
            console.log(self.packageRecord);
            if (!value.data.data.user_package.next_page_url) {
              self.moreRecords = false;
            }else{
              self.moreRecords = true;
            }
            
  
          })
          .catch(function (error) {
            self.isLoading = false;
            self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
          });
      }
    },
  };
  </script>
  
  <style scoped>
  
    /* .scrollable-container {
    max-height: 240px; 
    overflow-y: auto; 
    } */
    .notification-card {
        align-items: center;
        background: linear-gradient(90deg, #152331 0%, #000000 100%);
        color: #fff;
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 7px;
    }
  
  
    .title {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1px;
    }
  
    .button-group .btn-timeframe {
        border: none;
        background-color: #7E8088;
        color: #222223!important;
        margin-left: 0.25rem;
        border-radius: 8px;
        box-shadow: none;
        transition: background-color 0.3s;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 800;
        line-height: 26px;
        height: 26px;
        padding: 0 1rem;
    }
  
  /* text css */
  .text_main_title{
    flex-grow: 1;
    text-align: center;
  }
  
  .no-data-text{
    font-weight: 400;
    font-size: 16px;
    flex-grow: 1;
    text-align: center;
    margin-top: 40px;
    color: var(--Secondary, #7E8088) !important;
  }
  .text{
    color: var(--Main, #FFF);
    font-family: Poppins;
    font-style: normal;
  }
  
  .main_text{
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
    margin-top: 7px;
  }
  .weight_600{
    font-weight: 600;
  }
  
  .text_14{
    font-size: 14px;
  }
  
  /* text css end */
  
  .header {
    display: flex;
    /* padding: 0px 16px; */
    margin-top: 4rem !important;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  
  </style>
  